<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        逢甲大學團隊
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="">
        <p class="image_desc">足球賽式視覺化戰術版</p>
        <p class="image_desc">圖片提供：逢甲大學團隊</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          由於職業足球的盛行，國外有許多網站在公開或販售各聯賽足球數據的資料，這類公司由於開始蒐集數據的年代早，大多以電腦視覺物件辨識技術蒐集較大尺度的數據，然後搭配人力與數位資料蒐集系統進行細部數據蒐集與校正。而台灣的足球聯賽，仍受限於人力與技術，在數據紀錄上仍顯粗糙，需加強比賽球隊整體以及個別球員表現記錄其攻守數據，並剪輯比賽影片作為後續回顧之用。故逢甲大學團隊，發展足球軌跡與事件辨識AI，視覺化戰術版，用於各種技戰術教案分析。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據實證活動</div>
        <div class="content-desc">
          於實證期間，本計畫輔導廠商以既有在大專足球聯賽蒐集的影片進行數據計算與動態視覺化戰術板之開發，以人工檢證數據正確性，並製作展示案例。以現場模組進行拍攝，進行數據及動態戰術板解析，並製作展示案例。並依據蒐集教練與選手反饋進行改良以及結果評估。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          利用攝影機開發動態視覺化戰術板，追蹤主客隊陣形、各別球員位置、足球位置。紀錄單一賽事整隊數據如持球率、傳球次數、傳球成功率、球員跑動距離、瞬間最高跑動速度、球員跑動速度變化等，以及全場球員之跑動軌跡及球的傳遞軌跡紀錄。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">公數據應用效益/展望</div>
        <div class="content-desc">
          根據事件紀錄分析賽場事件如進球之起訖時間點，自動剪輯影片，並且搭配動態視覺化戰術板提供給球隊與球員進行回顧與技戰術分析之用。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11212',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-12.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
